// src/components/InfoBox.js
import React from 'react';
import '../App.css';

function InfoBox() {
  return (
    <div className="info-box">
      <p>
        Facing the Swarm Thought is a Metal Band from Munich, Germany. 
      </p>
      <h2>+++ News News News +++</h2>
      
      <div className="news-entry">
        <div className="date-box">
          <p className="date">2024-09-28</p>
        </div>
        <div className="news-box">
          <p className="news-content">
            We are proud to announce our newest release. <br/>
            <center>The Path</center><br/>
            Check out <a href="https://facingtheswarmthought.bandcamp.com">Bandcamp</a>!<br/>
            <iframe
              style={{ border: '0', width: '350px', height: '470px' }}
              src="https://bandcamp.com/EmbeddedPlayer/album=2925847188/size=large/bgcol=333333/linkcol=0f91ff/tracklist=false/transparent=true/"
              seamless
              title="Embedded Bandcamp Player"
            >
              <a href="https://facingtheswarmthought.bandcamp.com/album/the-path">
                The Path by Facing The Swarm Thought
              </a>
            </iframe>
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoBox;
