// src/App.js
import React from 'react';
import Title from './components/Title';
import InfoBox from './components/InfoBox';
import ContactBox from './components/ContactBox';
import Disclaimer from './components/Disclaimer';
import './App.css'; 

function App() {
  return (
    <div className="container">
      <Title />
      <InfoBox />
      <ContactBox />
      <Disclaimer />
    </div>
  );
}

export default App;
