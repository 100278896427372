// src/components/ContactBox.js
import React from 'react';
import '../App.css';

function ContactBox() {
    const user = 'uns';
    const domain = 'ftst.de'; 
  return (
    <div className="contact-box">
      <h2>Contact</h2>
      <p>Email: <a href={`mailto:${user}@${domain}`}>{user}@{domain}</a></p>
      <p>Spotify: <a href="https://open.spotify.com/intl-de/artist/6qiNRwi0hEd46IP3PfKnok?si=uK1CYOVDSSSSpyYMKAKovQ&nd=1&dlsi=377a8b18444640df">Facing on Spotify</a></p>
      <p>Instagram: <a href="https://www.instagram.com/facingtheswarmthought/">https://www.instagram.com/facingtheswarmthought/</a></p>
      <p>Bandcamp: <a href="https://facingtheswarmthought.bandcamp.com">https://facingtheswarmthought.bandcamp.com</a></p>
    </div>
  );
}

export default ContactBox;
