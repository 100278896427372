// src/components/Title.js
import React from 'react';
import '../App.css';
import image from '../assets/facing-the-swarm-thought-the-path-cover.png';

function Title() {
  return (
    <div className="title-container">
      <h1>Facing the Swarm Thought</h1>
      <img 
        src={image}
        alt="Facing The Swarm Thought Album Cover The Path"
        className="image" 
      />
    </div>
  );
}

export default Title;
