// src/components/Disclaimer.js
import React from 'react';
import '../App.css';

function Disclaimer() {
  return (
    <div className="disclaimer">
      <h3>Disclaimer</h3>
      <p>
        We don't use cookies. The content in the linked web pages is our's. 
      </p>
      <p>
        All Nazis fuck off! 
      </p>
    </div>
  );
}

export default Disclaimer;
